var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoFixedView',{attrs:{"gutter":0}},[_c('div',{staticClass:"flex min-h-0 flex-col flex-1 mx-4 pb-4 min-w-0"},[_c('div',{staticClass:"flex flex-col min-w-0"},[_c('FlotoPageHeader',{attrs:{"back-link":_vm.backLink},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc(_vm.moduleName, 2))+" ")]},proxy:true},{key:"title-append",fn:function(){return [(!_vm.searchTitle)?_c('FlotoSearchSelector',{attrs:{"id":"search-selector","module-name":_vm.moduleName,"allow-pin":"","persist-search":""},on:{"change":function($event){return _vm.handleSearchChange($event)}}}):_c('div',{staticClass:"inline-block ml-4 text-neutral cursor-pointer md-text-base flex-1"},[_vm._v(" "+_vm._s(_vm.searchTitle)+" ")])]},proxy:true},{key:"after-title",fn:function(){return [_c('KanbanColumnSelector',{staticClass:"mx-1",attrs:{"available-columns":_vm.columns,"module-name":_vm.moduleName},on:{"refresh":_vm.refreshList}}),_c('MCol',{staticClass:"flex items-center text-right justify-end",attrs:{"auto-size":""}},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{staticClass:"mr-1",attrs:{"id":"refresh-btn","shape":"circle","variant":"neutral-lighter","shadow":false},on:{"click":_vm.refreshList}},[_c('MIcon',{attrs:{"name":"sync"}})],1)]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('refresh'))+" ")])],1),_c('MTooltip',{attrs:{"placement":"topRight"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{attrs:{"id":"list-btn","shape":"circle","variant":"neutral-lighter","shadow":false},on:{"click":function (e) {
                    _vm.$emit('viewChange', 'list')
                    e.target.blur()
                  }}},[_c('MIcon',{attrs:{"name":"list"}})],1)]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('list_view'))+" ")])]},proxy:true}])})],1),(!_vm.searchTitle)?_c('MRow',{staticClass:"mb-2 flex flex-col min-w-0"},[_c('MCol',{class:{
          invisible:
            (_vm.currentSelectedSearch.systemName || '').indexOf('spam') >= 0 ||
            (_vm.currentSelectedSearch.systemName || '').indexOf('archive') >=
              0 ||
            (_vm.currentSelectedSearch.systemName || '') ===
              'requests watched by me',
        }},[_vm._t("search",function(){return [_c('FlotoSearchBar',{attrs:{"id":"search-bar","module-name":_vm.moduleName,"persist-criteria":true,"default-value":_vm.currentSelectedSearch.qualifications},on:{"change":_vm.refreshList}})]},{"refreshList":_vm.refreshList})],2)],1):_vm._e(),_c('div',{staticClass:"flex flex-col flex-1 min-h-0"},[_c('KanbanCore',{key:_vm.kanbanCoreKey,attrs:{"module-name":_vm.moduleName,"fetch-fn":_vm.getItems,"update-fn":_vm.updateFn}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }