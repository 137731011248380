<template>
  <div class="flex flex-col">
    <div class="my-2 flex items-between">
      <h4 class="flex-1">{{ title }}</h4>
    </div>
    <component
      :is="listComponent"
      ref="destinationList"
      :scrollable="false"
      :module-name="destinationModuleName"
      :has-actions="relationType !== 'child' && !disabled"
      :show-no-data="false"
      :hidden-columns="hiddenColumns"
      :fetch-fn="fetchRelations"
      :internal-link="!disabled"
      :linkable="!disabled"
    >
      <template
        v-if="!disabled"
        v-slot:action="{ item, stickAction, unstickAction }"
      >
        <FlotoDeleteBtn
          v-if="item.relationType !== 'child'"
          :message="$t('confirm_remove_item', { item: $tc('relation') })"
          @trigger="stickAction"
          @cancel="unstickAction"
          @confirm="removeRelation(item)"
        >
          <MTooltip>
            <template v-slot:trigger>
              <MIcon name="unlink" size="lg" />
            </template>
            {{ $t('remove') }}
          </MTooltip>
        </FlotoDeleteBtn>
      </template>
    </component>
  </div>
</template>

<script>
import TicketSelectionList from '@components/item-selection-list/ticket-list'
import KnowledgeSelectionList from '@components/item-selection-list/knowledge-list'
import AssetSelectionList from '@components/item-selection-list/asset-list'
import ContractSelectionList from '@components/item-selection-list/contract-list'
import PurchaseSelectionList from '@components/item-selection-list/purchase-list'
import ProjectSelectionList from '@components/item-selection-list/project-list'
import { getRelationsApi, deleteRelationApi } from './relation-api'
import { transformTicketForList } from '@data/ticket'
import { transformKnowledge } from '@data/knowledge'
import { transformAssetForList } from '@data/asset'
import { transformContract } from '@data/contract'
import { transformPurchaseForList } from '@data/purchase'
import { transformProjectForList } from '@data/project'

export default {
  name: 'RelationContent',
  props: {
    disabled: { type: Boolean, default: false },
    sourceModuleName: { type: String, required: true },
    destinationModuleName: { type: String, required: true },
    sourceId: { type: Number, required: true },
    title: { type: String, required: true },
    relationType: { type: String, default: undefined },
    isApproval: { type: Boolean, default: false },
  },
  computed: {
    hiddenColumns() {
      if (
        [this.$constants.REQUEST, this.$constants.PROBLEM].indexOf(
          this.destinationModuleName
        ) >= 0
      ) {
        return ['due_by']
      }
      if (this.destinationModuleName === this.$constants.ASSET) {
        return ['ipAddress', 'hostName']
      }
      return undefined
    },
    listComponent() {
      if (
        [
          this.$constants.REQUEST,
          this.$constants.PROBLEM,
          this.$constants.CHANGE,
          this.$constants.RELEASE,
        ].indexOf(this.destinationModuleName) >= 0
      ) {
        return TicketSelectionList
      }
      if (this.destinationModuleName === this.$constants.KNOWLEDGE) {
        return KnowledgeSelectionList
      }
      if (this.destinationModuleName === this.$constants.ASSET) {
        return AssetSelectionList
      }
      if (this.destinationModuleName === this.$constants.CONTRACT) {
        return ContractSelectionList
      }
      if (this.destinationModuleName === this.$constants.PURCHASE) {
        return PurchaseSelectionList
      }
      if (this.destinationModuleName === this.$constants.PROJECT) {
        return ProjectSelectionList
      }
      return 'template'
    },
  },
  methods: {
    transformFn() {
      if (
        [
          this.$constants.REQUEST,
          this.$constants.PROBLEM,
          this.$constants.CHANGE,
          this.$constants.RELEASE,
        ].indexOf(this.destinationModuleName) >= 0
      ) {
        return transformTicketForList
      }
      if (this.destinationModuleName === this.$constants.KNOWLEDGE) {
        return transformKnowledge
      }
      if (this.destinationModuleName === this.$constants.ASSET) {
        return transformAssetForList
      }
      if (this.destinationModuleName === this.$constants.CONTRACT) {
        return transformContract
      }
      if (this.destinationModuleName === this.$constants.PURCHASE) {
        return transformPurchaseForList
      }
      if (this.destinationModuleName === this.$constants.PROJECT) {
        return transformProjectForList
      }
      return (e) => e
    },
    fetchRelations(_, criterias, limit, offset) {
      return getRelationsApi(
        this.sourceModuleName,
        this.sourceId,
        this.destinationModuleName,
        this.relationType,
        limit,
        offset,
        this.isApproval ? { isApproval: true } : {}
      ).then((data) => {
        // send itmes without transform: linked asset list for copy parent request data feature
        this.$emit('related-items-received', data.items)
        data.items = data.items.map(this.transformFn())
        this.$emit(
          'related-ids-received',
          data.items.map(({ id }) => id)
        )
        return data
      })
    },
    removeRelation(item) {
      deleteRelationApi(item.relationId, this.sourceModuleName).then(() => {
        this.$refs.destinationList.refresh()
        this.$emit('relation-removed', {
          sourceModuleName: this.sourceModuleName,
          destinationModuleName: this.destinationModuleName,
        })
      })
    },
  },
}
</script>
