import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@/src/utils/get-module-translator'
import constants from '@/src/constants/index'
import { transformTicketForServer } from '@data/ticket'
import { transformAssetForServer } from '@data/asset'
import { transformPurchaseForServer } from '@data/purchase'
import { transformKnowledgeForServer } from '@data/knowledge'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()

export function bulkAddRelationApi(
  moduleName,
  id,
  relatedModuleName,
  relatedIds
) {
  return api.post(
    `/${moduleName}/${id}/relation/bulk/create`,
    {
      destinationModel: relatedModuleName,
      destinationIds: relatedIds,
      type: 'related',
    },
    {
      message: __rootT('added_successfully', {
        resource: __rootTc('relation', relatedIds.length),
      }),
    }
  )
}

export function getRelationsApi(
  sourceModuleName,
  sourceId,
  targetModuleName,
  type = undefined,
  limit,
  offset,
  additionalUrlParams = {}
) {
  return api
    .get(`/${sourceModuleName}/${sourceId}/relation/get/${targetModuleName}`, {
      params: {
        type,
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map((relation) => ({
          ...relation.obj,
          relationId: relation.id,
          relationType: relation.type,
        })),
        total: data.totalCount,
      }
    })
}

export function deleteRelationApi(relationId, moduleName) {
  return api.delete(`/relation/${moduleName}/${relationId}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('relation'),
    }),
  })
}

export function createAndRelateApi(parentModel, parentId, data, destinationId) {
  const postData = {
    destinationModel: data.moduleName,
    ...(destinationId ? { destinationId: destinationId } : {}),
  }
  if (
    [
      constants.REQUEST,
      constants.PROBLEM,
      constants.CHANGE,
      constants.RELEASE,
      constants.SERVICE_CATALOG,
    ].indexOf(data.moduleName) >= 0
  ) {
    postData.obj = transformTicketForServer(data)
  }
  if (data.moduleName === constants.KNOWLEDGE) {
    postData.obj = transformKnowledgeForServer(data)
  }
  if (
    [
      constants.ASSET_HARDWARE,
      constants.ASSET_SOFTWARE,
      constants.ASSET_NON_IT,
      constants.ASSET_CONSUMABLE,
    ].indexOf(data.moduleName) >= 0
  ) {
    postData.obj = transformAssetForServer(data)
  }
  if ([constants.PURCHASE].indexOf(data.moduleName) >= 0) {
    postData.obj = transformPurchaseForServer(data)
  }
  return api.post(
    `/${parentModel}/${parentId}/relation/createRelate`,
    postData,
    {
      message: __rootT('added_successfully', {
        resource: __rootTc('relation'),
      }),
    }
  )
}

export function getEligableRelationApi(
  source,
  moduleName,
  qualifications,
  limit,
  offset
) {
  return api
    .post(
      `${source.moduleName}/${source.id}/searchEligibleRelation/${moduleName}`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          type: 'related',
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList || [],
        total: data.totalCount,
      }
    })
}
export function getRelationCountApi(moduleName, id) {
  return api.get(`${moduleName}/${id}/relation/getcount`)
}
