<template>
  <a
    :class="{
      'cursor-text':
        disabled ||
        mainUser.systemUser ||
        userId === systemUserId ||
        userId === guestUserId,
      ...triggerClass,
    }"
    @click.stop="open"
  >
    <slot v-bind="{ open, close, mainUser }">
      {{ mainUser.name || mainUser.email || mainUser.userLogOnName }}
    </slot>
    <FlotoDrawer
      v-if="isOpen"
      width="50%"
      default-open
      :scrolled-content="false"
      @hide="close"
    >
      <template v-slot:trigger>
        <span />
      </template>
      <template v-slot:title>
        {{ mainUser.name || mainUser.email || mainUser.userLogOnName }}
      </template>
      <FlotoContentLoader :loading="loading" class="h-100">
        <FlotoFixedView>
          <div v-if="!loading" class="h-100 flex flex-col">
            <div class="flex flex-col">
              <div class="flex items-center">
                <div>
                  <FlotoUserAvatar :size="70" :avatar="fullUser.avatar" />
                </div>
                <div class="ml-4">
                  <h3 class="mb-0">
                    {{ fullUser.name }}
                  </h3>
                  <small v-if="fullUser.email">
                    <a :href="`mailto:${fullUser.email}`">{{
                      fullUser.email
                    }}</a>
                  </small>
                  <small v-else>
                    {{ fullUser.userLogOnName }}
                  </small>
                  <!-- <small v-if="fullUser.contactNo" class="mx-2">
                <a :href="`tel:${fullUser.contactNo}`">
                  {{ fullUser.contactNo }}
                </a>
              </small> -->
                  &nbsp;(
                  <template v-if="fullUser.userType === 'requester'">
                    <small>
                      {{
                        fullUser.verified ? $t('verified') : $t('unverified')
                      }}
                      {{ $tc('requester') }}
                    </small>
                  </template>
                  <template v-else>
                    <small>{{ $tc('technician') }}</small>
                  </template>
                  )
                  <MTag
                    v-if="fullUser.vip"
                    size="large"
                    variant="orange"
                    :closable="false"
                    class="ml-2"
                  >
                    {{ $t('vip') }}
                  </MTag>
                </div>
              </div>
              <MTab v-model="currentTab">
                <MTabPane key="profile" :tab="$t('profile')" />
                <MTabPane
                  v-if="hasRequestModule"
                  key="recent_requests"
                  :tab="`${$t('recent')} ${$tc('request', 2)}`"
                />
                <MTabPane
                  v-if="canAccessContactOps"
                  key="recent_calls"
                  :tab="`${$t('recent')} ${$tc('call', 2)}`"
                />
                <MTabPane
                  v-if="hasAssetModule"
                  key="assets"
                  :tab="$t('assets')"
                />
              </MTab>
            </div>
            <div class="h-100">
              <FlotoScrollView>
                <UserProfile v-if="currentTab === 'profile'" :user="fullUser" />
                <UserAssets v-if="currentTab === 'assets'" :user="fullUser" />
                <RecentRequests
                  v-if="currentTab === 'recent_requests'"
                  :user="fullUser"
                />
                <RecentCalls
                  v-if="currentTab === 'recent_calls' && fullUser.id"
                  :user-id="fullUser.id"
                />
              </FlotoScrollView>
            </div>
          </div>
        </FlotoFixedView>
      </FlotoContentLoader>
    </FlotoDrawer>
  </a>
</template>

<script>
import { LicenseComputed } from '@state/modules/license'
import { TechnicianComputed } from '@state/modules/technician'
import { PreferenceComputed } from '@state/modules/preference'
import { getGlobalUserApi } from '@modules/users/users-api'
import UserProfile from './user-profile'
import UserAssets from './user-assets'
import RecentRequests from './recent-requests'
import RecentCalls from './recent-calls'

export default {
  name: 'FlotoUserDrawer',
  // @TODO: Solve contactOpsConfigContext not found error
  components: { UserProfile, RecentRequests, UserAssets, RecentCalls },
  inject: {
    contactOpsConfigContext: { config: {} },
  },
  props: {
    disabled: { type: Boolean, default: false },
    user: {
      type: Object,
      default() {
        return {}
      },
    },
    triggerClass: {
      type: Object,
      default() {
        return {}
      },
    },
    userId: { type: Number, default: undefined },
    // eslint-disable-next-line
    immediateApiCall: { type: Boolean, default: true },
  },
  data() {
    this.systemUserId = -93102
    this.guestUserId = -93103
    return {
      isOpen: false,
      loading: true,
      currentTab: 'profile',
      fullUser: {
        ...this.user,
      },
    }
  },
  computed: {
    ...PreferenceComputed,
    ...TechnicianComputed,
    ...LicenseComputed,
    mainUser() {
      return this.fullUser
    },
    hasAssetModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
    },
    hasRequestModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0
      )
    },
    canAccessContactOps() {
      if (this.isPortalLogin) {
        return false
      }
      const isContactOpsEnabled = (this.contactOpsConfigContext || {}).config
        .enabled
      const supportContactOps = this.contactOpsEnabled
      return isContactOpsEnabled && supportContactOps
    },
  },
  created() {
    if (
      (this.userId && this.immediateApiCall) ||
      this.userId === this.systemUserId ||
      this.userId === this.guestUserId
    ) {
      let user = {}
      if (this.userId === this.systemUserId) {
        user = {
          name: 'System',
        }
      } else if (this.userId === this.guestUserId) {
        user = {
          name: 'Guest User', // @TODO change name for guest user
        }
      } else {
        user = this.technician(this.userId)
      }
      if (
        (user && user.id) ||
        this.userId === this.systemUserId ||
        this.userId === this.guestUserId
      ) {
        this.fullUser = user
        this.loading = false
      } else {
        getGlobalUserApi(this.userId, { archived: true }).then((user) => {
          this.fullUser = user
          this.loading = false
        })
      }
    }
  },
  methods: {
    open() {
      if (this.disabled) {
        return
      }
      if (this.fullUser.systemUser) {
        return
      }
      if (
        this.userId === this.systemUserId ||
        this.userId === this.guestUserId
      ) {
        return
      }
      this.isOpen = true
      this.getProfile()
    },
    close() {
      this.isOpen = false
    },
    getProfile() {
      const userId = this.immediateApiCall ? this.mainUser.id : this.userId
      return getGlobalUserApi(userId, { archived: true })
        .then((user) => {
          this.fullUser = user
          this.loading = false
        })
        .catch(() => {
          this.isOpen = false
        })
    },
  },
}
</script>
