<template>
  <MRow
    class="h-100 ignore-mousetrap min-w-0"
    v-bind="advancedValueInput ? { gutter: 0 } : {}"
  >
    <!-- dummy input for focus on mount -->
    <!-- <input ref="dummyInputRef" style="width: 0; border: none" /> -->
    <MCol class="flex flex-col h-100 min-w-0">
      <div v-if="operators.length" class="flex-col flex">
        <MCol>
          <div class="pt-2">
            <small class="text-neutral">{{ $t('operator') }}</small>
          </div>
          <div>
            <div v-if="allOperators.length <= 1">
              <MCheckableTag
                v-for="o in allOperators"
                :key="o.value"
                :class="{ disabled: o.disabled }"
                :checked="currentOperator.value === o.value"
                @input="currentOperator = o"
              >
                {{ o.name }}
              </MCheckableTag>
            </div>
            <FlotoDropdownPicker
              v-else
              as-input
              searchable
              v-bind="$attrs"
              :allow-clear="false"
              :options="allOperators"
              :value="currentOperator ? currentOperator.value : undefined"
              @change="handleChange"
            />
          </div>
          <div>
            <MDivider class="mb-1 mt-2" />
          </div>
        </MCol>
      </div>
      <div class="flex-1 overflow-hidden" style="min-width: 300px">
        <FlotoForm
          v-if="unaryOperators.indexOf(currentOperator.value) === -1"
          ref="form"
          class="h-100 flex flex-col"
          layout="vertical"
          :show-notification="false"
          @submit="formSubmitted"
        >
          <FlotoScrollView>
            <MCol v-if="source.moduleNameSelection">
              <FlotoFormItem :validation-label="$tc('module')" rules="required">
                <FlotoModuleSelector
                  v-model="selectedSourceModel"
                  as-dropdown
                  :multiple="source.onlyModuleNameSelection"
                  :get-popup-container="getPopupContainer"
                  :available-modules="
                    moduleName === $constants.APPROVAL
                      ? options.map((o) => o.key)
                      : [
                          $constants.REQUEST,
                          $constants.PROBLEM,
                          $constants.CHANGE,
                          $constants.RELEASE,
                          $constants.PROJECT,
                        ]
                  "
                />
              </FlotoFormItem>
            </MCol>
            <MCol
              v-if="
                isDropdown ||
                fieldValueOperators.indexOf(currentOperator.value) >= 0
              "
            >
              <MInput
                v-if="isSearchAllowed"
                ref="searchBox"
                v-model="searchTerm"
                :placeholder="$tc('search')"
                class="mb-2"
              >
                <template v-slot:suffix>
                  <MIcon name="search" />
                </template>
              </MInput>
              <FlotoFormItem
                class="m-0 p-0"
                :label="$t('value')"
                :rules="dropdownValuesValidation"
                :module-name="moduleName"
              >
                <DropdownValues
                  :key="currentOperator.value"
                  v-model="selectedValue"
                  :options="getDropdownValueOptions"
                  :single-value-selection="
                    ['equal', 'not_equal', ...fieldValueOperators].indexOf(
                      currentOperator.value
                    ) >= 0
                  "
                />
              </FlotoFormItem>
            </MCol>
            <MCol v-else-if="isDateSpecialField">
              <CreatedTime
                :value.sync="selectedValue"
                :to-value.sync="selectedToValue"
                :operator="currentOperator"
                :type="type"
                :module-name="moduleName"
              />
            </MCol>
            <MCol v-else>
              <FlotoFormItem
                vid="fromDateTime"
                :validation-label="$t('value')"
                :rules="validationRules"
                class="m-0 p-0"
              >
                <FlotoDropdownPicker
                  v-if="fieldValueOperators.indexOf(currentOperator.value) >= 0"
                  id="field-value-input"
                  v-model="selectedValue"
                  as-input
                  searchable
                  :options="source.fieldDetailsOptions"
                  v-bind="$attrs"
                />
                <FlotoValueInput
                  v-else
                  v-model="selectedValue"
                  :input-type="getInputType"
                  :value-type="valueType"
                  :day-time-trigger-style="{ 'mb-6': true }"
                  :get-popup-container="getPopupContainer"
                  :placeholder="placeholder"
                  :module-name="moduleName"
                  :operator="currentOperator.value"
                  :rules="valueInputValidationRules"
                  :additional-options="additionalOptions"
                  :available-asset-type="
                    availableAssetType || searchBarContext.availableAssetType
                  "
                  :auto-complete-search="paramName === 'tags'"
                  v-bind="fromInputSpecificProps"
                  auto-focus
                  multiple
                />
              </FlotoFormItem>
              <template
                v-if="betweenOperators.indexOf(currentOperator.value) >= 0"
              >
                <div class="mb-4 flex items-center justify-center">
                  {{ $t('to') }}
                </div>
                <FlotoFormItem
                  vid="toDateTime"
                  :validation-label="$t('value')"
                  class="m-0 p-0"
                  :rules="{
                    ...validationRules,
                    ...(type === 'number'
                      ? // eslint-disable-next-line
                        { min_value: selectedValue + 1, nonzero: false }
                      : {}),
                  }"
                >
                  <FlotoValueInput
                    v-model="selectedToValue"
                    :input-type="type"
                    :get-popup-container="getPopupContainer"
                    :value-type="valueType"
                    :placeholder="placeholder"
                    :module-name="moduleName"
                    :operator="currentOperator.value"
                    :additional-options="additionalOptions"
                    :available-asset-type="
                      availableAssetType || searchBarContext.availableAssetType
                    "
                    auto-focus
                    multiple
                    v-bind="inputSpecificProps"
                  />
                </FlotoFormItem>
              </template>
            </MCol>
          </FlotoScrollView>
          <template v-slot:submit="{ invalid }">
            <div class="py-2">
              <MCol class="flex justify-end">
                <MButton :disabled="invalid" outline size="small" type="submit">
                  {{ $t('done') }}
                </MButton>
              </MCol>
            </div>
          </template>
        </FlotoForm>
      </div>
    </MCol>
  </MRow>
</template>

<script>
import {
  unaryOperators,
  durationOperators,
  betweenOperators,
  fieldValueOperators,
} from '@data/operator'
import DropdownValues from './value-picker/dropdown-values'
import CreatedTime from './value-picker/created-time'

export default {
  name: 'ValueInput',
  components: { DropdownValues, CreatedTime },
  inject: ['searchBarContext'],
  props: {
    type: { type: String, required: true },
    moduleName: { type: String, required: true },
    valueType: { type: String, required: true },
    paramName: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    allowDecimal: { type: Boolean, default: false },
    value: {
      type: [String, Object, Array, Number, Boolean],
      default: undefined,
    },
    toValue: { type: [String, Object, Array, Number], default: undefined },
    sourceModel: { type: [String], default: undefined },
    operator: { type: [String, Object], default: undefined },
    customFieldType: { type: String, default: undefined },
    operators: {
      type: Array,
      default() {
        return []
      },
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    additionalOptions: {
      type: Array,
      default() {
        return []
      },
    },
    availableAssetType: { type: [String, Array], default: undefined },
    source: { type: Object, required: true },
    advancedValueInput: { type: Boolean, default: false },
  },
  data() {
    this.unaryOperators = unaryOperators
    this.durationOperators = durationOperators
    this.betweenOperators = betweenOperators
    this.fieldValueOperators = fieldValueOperators
    return {
      currentOperator: this.operator.value ? this.operator : this.operators[0],
      selectedValue: undefined,
      selectedToValue: undefined,
      searchTerm: '',
      preparedValueOptions: [],
      selectedSourceModel: '',
    }
  },
  computed: {
    dropdownValuesValidation() {
      const rules = {
        required: true,
        min: 1,
      }
      if (this.customFieldType === 'dropdown') {
        rules.required = false
        rules.min = 0
        rules.required_list_with_none = true
      }
      return rules
    },
    validationRules() {
      const rules = {}
      if (this.type !== 'boolean') {
        rules.required = true
      }
      if (['date', 'dateTime'].indexOf(this.type) >= 0) {
        rules.nonzero = true
      }
      return rules
    },
    valueInputValidationRules() {
      let rules = {}
      if (['tags', 'multiStringValue'].indexOf(this.type) >= 0) {
        rules.required = true
      }
      return rules
    },
    allOperators() {
      // if date special field and selected variable operand
      if (this.isDateSpecialField) {
        if (this.selectedValue && /^[a-z]+/.test(this.selectedValue)) {
          return this.operators.map((o) => ({
            ...o,
            readOnly: ['between', ...durationOperators].indexOf(o.value) >= 0,
          }))
        } else {
          return this.operators.map((o) => ({ ...o, readOnly: false }))
        }
      }
      return this.operators
    },
    isDropdown() {
      if (this.type === 'requester' && this.currentOperator.value === 'equal') {
        return true
      }
      return this.type === 'dropdown'
    },
    isDateSpecialField() {
      return ['createdTime', 'dueBy', 'createdDate'].indexOf(this.type) >= 0
    },
    fromInputSpecificProps() {
      if (this.paramName === 'used_by_users') {
        return {
          keepUnassigned: true,
        }
      }
      if (['date', 'dateTime'].indexOf(this.type) >= 0) {
        return {
          maxDate:
            betweenOperators.indexOf(this.currentOperator.value) >= 0
              ? this.selectedToValue
              : undefined,
          allowClear: this.customFieldType !== 'datetime',
        }
      }
      if (this.type === 'number') {
        return {
          precision: this.allowDecimal ? 2 : 0,
        }
      }
      if (
        this.type === 'dependent' ||
        this.type === 'multiSelectDropdown' ||
        this.type === 'approvalModuleSelector'
      ) {
        return {
          options: this.options,
        }
      }
      return {}
    },
    inputSpecificProps() {
      if (['date', 'dateTime'].indexOf(this.type) >= 0) {
        return {
          minDate: this.selectedValue,
          allowClear: this.customFieldType !== 'datetime',
        }
      }
      // refactor requester_email to requester
      // if (this.type === 'requester_email') {
      //   return {
      //     type: 'email',
      //   }
      // }
      if (this.type === 'number') {
        return {
          precision: this.allowDecimal ? 2 : 0,
        }
      }
      return {}
    },
    getInputType() {
      if (this.type === 'dateTime' || this.type === 'date') {
        return durationOperators.indexOf(this.currentOperator.value) >= 0
          ? 'duration'
          : 'date'
      }
      if (['barCode', 'hostName'].indexOf(this.paramName) >= 0) {
        return this.currentOperator.value === 'contains' ? 'string' : this.type
      }
      return this.type
    },
    isSearchAllowed() {
      return this.source.searchableDropdown
    },
    getDropdownValueOptions() {
      let currentOptions =
        fieldValueOperators.indexOf(this.currentOperator.value) >= 0
          ? this.source.fieldDetailsOptions
          : this.options
      if (this.isSearchAllowed) {
        return this.preparedValueOptions
      }
      return currentOptions
    },
  },
  watch: {
    currentOperator(newValue, prevValue) {
      if (newValue && unaryOperators.indexOf(newValue.value) >= 0) {
        this.$emit('selected', {
          operator: newValue,
          value: undefined,
          toValue: undefined,
        })
      }
      if (
        (this.type === 'requester' &&
          this.options.length &&
          this.operators.find((o) => o.value === 'equal')) ||
        ['between', ...durationOperators].indexOf(newValue.value) >= 0
      ) {
        if (['number'].indexOf(this.type) >= 0) {
          this.selectedValue = 0
        } else {
          this.selectedValue = undefined
        }
      }
      if (
        newValue !== prevValue &&
        (this.source.fieldDetailsOptions || []).length
      ) {
        this.selectedValue = undefined
      }
      if (newValue !== prevValue && this.isDropdown) {
        this.selectedValue = undefined
      }
      if (
        ['barCode', 'hostName'].indexOf(this.paramName) >= 0 &&
        this.type === 'tags'
      ) {
        this.selectedValue = undefined
      }
    },
    selectedValue(newValue) {
      if (
        this.isDateSpecialField &&
        typeof newValue === 'string' &&
        ['between', ...durationOperators].indexOf(this.currentOperator.value) >=
          0
      ) {
        this.currentOperator = this.allOperators.find(
          (o) => o.value === 'equal'
        )
      }
    },
    value: {
      immediate: true,
      handler(newValue) {
        if (['number'].indexOf(this.type) >= 0 && !newValue) {
          this.selectedValue = 0
        } else {
          this.selectedValue = this.value
          this.selectedSourceModel = this.sourceModel || ''
        }
      },
    },
    toValue: {
      immediate: true,
      handler(newValue) {
        if (['number'].indexOf(this.type) >= 0 && !newValue) {
          this.selectedToValue = 0
        } else {
          this.selectedToValue = this.toValue
        }
      },
    },
    searchTerm(newValue) {
      let currentOptions =
        fieldValueOperators.indexOf(this.currentOperator.value) >= 0
          ? this.source.fieldDetailsOptions
          : this.options
      if (newValue && this.isSearchAllowed) {
        this.preparedValueOptions = currentOptions.filter(
          (o) =>
            (o.name || '' || o.systemName || '' || o.displayName || '')
              .toLowerCase()
              .indexOf((newValue || '').toLowerCase()) >= 0
        )
      } else {
        this.preparedValueOptions = currentOptions
      }
    },
  },
  created() {
    if (this.searchTerm === '' || !this.searchTerm) {
      this.preparedValueOptions =
        fieldValueOperators.indexOf(this.currentOperator.value) >= 0
          ? this.source.fieldDetailsOptions
          : this.options
    }
  },
  methods: {
    handleChange(itemKey) {
      const item = this.allOperators.find((o) => o.value === itemKey)
      this.currentOperator = item
      this.preparedValueOptions =
        fieldValueOperators.indexOf(this.currentOperator.value) >= 0
          ? this.source.fieldDetailsOptions
          : this.options
    },
    getPopupContainer() {
      const e = this.$el.closest('.__panel')
      if (e) {
        return e
      }
      return document.body
    },
    formSubmitted() {
      this.$emit('selected', {
        operator: this.currentOperator,
        value: this.selectedValue,
        toValue: this.selectedToValue,
        sourceModel: this.selectedSourceModel,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.checkbox-menu {
  display: flex;
  flex-direction: column;
}
</style>
