<template>
  <div class="flex flex-col">
    <div class="flex items-center justify-center">
      <Croppa
        v-model="croppa"
        class="profile-picture-selector"
        :placeholder="$t('select_image')"
        placeholder-color="#000"
        :placeholder-font-size="16"
        canvas-color="transparent"
        :initial-image="initialImage"
        :show-remove-button="false"
        :disable-click-choose="true"
        :width="size"
        :prevent-white-space="circle"
        :height="size"
        :image-border-radius="size"
        :quality="2"
        @file-type-mismatch="handleFileTypeMismatch"
        @init="onInit"
      />
    </div>
    <div class="my-2 flex justify-center items-center">
      <a class="text-neutral-light mx-2" @click="handleSelectFile">
        {{ $tc('change') }}
      </a>
      <span class="text-neutral-light"> | </span>
      <a class="text-secondary-red mx-2" @click="handleRemoveProfilePicture">
        {{ $tc('remove') }}
      </a>
    </div>
  </div>
</template>

<script>
import Pick from 'lodash/pick'
import Croppa from 'vue-croppa'
import api from '@api'

export default {
  name: 'ImageSelector',
  components: { Croppa: Croppa.component },
  model: { event: 'change' },
  props: {
    value: { type: String, default: undefined },
    size: { type: Number, default: 180 },
    circle: { type: Boolean, default: false },
  },
  data() {
    return {
      isDirty: false,
      croppa: {},
    }
  },
  computed: {
    initialImage() {
      return this.value
    },
  },
  watch: {
    value() {
      this.$nextTick(() => {
        this.croppa.refresh()
      })
    },
  },
  methods: {
    handleFileTypeMismatch() {
      this.$errorNotification({
        duration: 5,
        message: this.$t('unsupported_file_type'),
        description: this.$t('only_supported_file_types', {
          fileTypes: 'jpeg, jpg, png, svg',
        }),
      })
    },
    onInit() {
      if (this.circle) {
        this.croppa.addClipPlugin(function(ctx, x, y, w, h) {
          ctx.beginPath()
          ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
          ctx.closePath()
        })
      }
    },
    getFile() {
      if (!this.isDirty) {
        return Promise.resolve()
      }
      return new Promise((resolve, reject) => {
        this.croppa.generateBlob(
          (blob) => {
            this.uploadFile(blob)
              .then(resolve)
              .catch(reject)
          },
          'image/png',
          0.8
        )
      })
    },
    uploadFile(blob) {
      if (!blob) {
        return Promise.resolve(null)
      }
      const formData = new FormData()
      formData.append('file', blob)
      return api
        .post('upload/file', formData, {
          notify: false,
        })
        .then((data) => Pick(data, ['realName', 'refFileName']))
    },
    handleSelectFile() {
      this.isDirty = true
      this.$nextTick(() => {
        this.croppa.chooseFile()
      })
    },
    handleRemoveProfilePicture() {
      this.$emit('remove')
      this.croppa.remove()
      this.croppa.refresh()
      this.isDirty = false
    },
  },
}
</script>

<style lang="less">
@import '~vue-croppa/dist/vue-croppa.css';
.profile-picture-selector {
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}
</style>
