<script>
import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'
import Bus from '@utils/emitter'
import {
  getNotificationPermission,
  showDesktopNotification,
} from '@utils/desktop-notifications'
import { transformNotification } from '@data/notification'
import { getDetailLinkForModule } from '@utils/get-module-detail-link'
import { token, isLoggedIn, getCurrentUser } from '@utils/auth'
const CHANNEL = '/users/refreshvuex/key'
const CHAT_CHANNEL = '/users/refreshvuex/chat'
const CHAT_COUNT_CHANNEL = '/users/refreshvuex/pendingChatCount'
const RECONNECT_TIME = 2000 // in miliseconds
const UINOTIFICATION_CHANNEL = '/users/refreshvuex/webnotification'
const UINOTIFICATIONCOUNT_CHANNEL = '/users/refreshvuex/webnotification/count'
const CONTACTOPS_CHANNEL = '/users/refreshvuex/contactops'

const ActionsMap = [
  { serverKey: 'branding', vuexKey: 'branding', action: 'fetch' },
  { serverKey: 'form_request', vuexKey: 'form/request', action: 'fetch' },
  { serverKey: 'form_problem', vuexKey: 'form/problem', action: 'fetch' },
  { serverKey: 'form_change', vuexKey: 'form/change', action: 'fetch' },
  { serverKey: 'form_release', vuexKey: 'form/release', action: 'fetch' },
  { serverKey: 'form_asset', vuexKey: 'form/asset', action: 'fetch' },
  { serverKey: 'form_user', vuexKey: 'form/user', action: 'fetch' },
  { serverKey: 'form_feedback', vuexKey: 'form/feedback', action: 'fetch' },
  { serverKey: 'form_purchase', vuexKey: 'form/purchase', action: 'fetch' },
  { serverKey: 'form_task', vuexKey: 'form/task', action: 'fetch' },
  {
    serverKey: 'form_co_ops_call_history',
    vuexKey: 'form/co_ops_call_history',
    action: 'fetch',
  },
  { serverKey: 'form_project', vuexKey: 'form/project', action: 'fetch' },
  { serverKey: 'form_contract', vuexKey: 'form/contract', action: 'fetch' },
  {
    serverKey: 'form_msp_company',
    vuexKey: 'form/msp_company',
    action: 'fetch',
  },
  { serverKey: 'impact', vuexKey: 'impact', action: 'fetch' },
  { serverKey: 'location', vuexKey: 'location', action: 'fetch' },
  { serverKey: 'organization', vuexKey: 'organization', action: 'fetch' },
  {
    serverKey: 'savedqualification_request',
    vuexKey: 'page/request',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_problem',
    vuexKey: 'page/problem',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_change',
    vuexKey: 'page/change',
    action: 'fetch',
  },

  {
    serverKey: 'savedqualification_release',
    vuexKey: 'page/release',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_asset_hardware',
    vuexKey: 'page/asset_hardware',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_asset_software',
    vuexKey: 'page/asset_software',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_asset_non_it',
    vuexKey: 'page/asset_non_it',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_asset_consumable',
    vuexKey: 'page/asset_consumable',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_task',
    vuexKey: 'page/task',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_purchase',
    vuexKey: 'page/purchase',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_contract',
    vuexKey: 'page/contract',
    action: 'fetch',
  },
  {
    serverKey: 'savedqualification_project',
    vuexKey: 'page/project',
    action: 'fetch',
  },
  {
    serverKey: 'tenantpreference',
    vuexKey: 'preference',
    action: 'fetchTenantPrefrence',
  },
  {
    serverKey: 'priority',
    vuexKey: 'priority',
    action: 'fetch',
  },
  {
    serverKey: 'source',
    vuexKey: 'source',
    action: 'fetch',
  },
  { serverKey: 'status_request', vuexKey: 'status/request', action: 'fetch' },
  { serverKey: 'status_problem', vuexKey: 'status/problem', action: 'fetch' },
  { serverKey: 'status_change', vuexKey: 'status/change', action: 'fetch' },
  { serverKey: 'status_release', vuexKey: 'status/release', action: 'fetch' },
  {
    serverKey: 'supportportal_config',
    vuexKey: 'supportPortalConfig',
    action: 'fetch',
  },
  {
    serverKey: 'sso_config',
    vuexKey: 'ssoConfig',
    action: 'fetch',
  },
  {
    serverKey: 'technician',
    vuexKey: 'technician',
    action: 'fetch',
  },
  {
    serverKey: 'urgency',
    vuexKey: 'urgency',
    action: 'fetch',
  },
  {
    serverKey: 'group',
    vuexKey: 'userGroup/technician',
    action: 'fetch',
  },
  {
    serverKey: 'group',
    vuexKey: 'userGroup/requester',
    action: 'fetch',
  },
  { serverKey: 'department', vuexKey: 'department', action: 'fetch' },
  {
    serverKey: 'category_request',
    vuexKey: 'category/request',
    action: 'fetch',
  },
  {
    serverKey: 'category_problem',
    vuexKey: 'category/problem',
    action: 'fetch',
  },
  { serverKey: 'category_change', vuexKey: 'category/change', action: 'fetch' },
  {
    serverKey: 'category_release',
    vuexKey: 'category/release',
    action: 'fetch',
  },
  {
    serverKey: 'field_mapping',
    vuexKey: 'fieldMapping/request',
    action: 'fetch',
  },
  {
    serverKey: 'software_type',
    vuexKey: 'softwareType',
    action: 'fetch',
  },
  {
    serverKey: 'msp_config',
    vuexKey: 'mspConfig',
    action: 'fetch',
  },
  {
    serverKey: 'logout',
    vuexKey: 'auth',
    action: 'logout',
  },
]

export default {
  name: 'SocketListener',
  props: {
    supportedVuexActions: { type: Array, default: undefined },
  },
  data() {
    this.vuexQueueCallInterval = null
    return {
      connected: false,
      executableActionMap: new Map(),
      immediateSync: false,
    }
  },
  created() {
    const logoutHandler = (data) => {
      this.disconnect()
    }
    Bus.$on('auth:logout', logoutHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('auth:logout', logoutHandler)
    })
    getNotificationPermission()
    this.makeSocket()
    this.connect()
    if (!this.immediateSync) {
      this.vuexQueueCallInterval = setInterval(() => {
        this.executeVuexCallActions()
      }, 60000)
    }
  },
  mounted() {
    // if (this.supportedVuexActions) {
    //   this.supportedVuexActions.forEach((action) => {
    //     const a = ActionsMap.find(({ vuexKey }) => vuexKey === action)
    //     if (a) {
    //       this.triggerAction(a.serverKey)
    //     }
    //   })
    // } else {
    //   ActionsMap.forEach(({ serverKey }) => this.triggerAction(serverKey))
    // }
  },
  beforeDestroy() {
    this.disconnect()
    if (this.vuexQueueCallInterval) {
      clearInterval(this.vuexQueueCallInterval)
      this.vuexQueueCallInterval = null
    }
  },
  methods: {
    reconnect() {
      setTimeout(() => {
        this.makeSocket()
        this.connect()
      }, RECONNECT_TIME)
    },
    makeSocket() {
      // let URL = `${window.location.origin}/api/mtdtsocket`
      if (isLoggedIn()) {
        const URL = `${window.location.origin}/api/mtdtsocket?mid=${token()}`
        this.socket = new SockJS(URL)
        this.stompClient = Stomp.over(this.socket)
      }
    },
    connect() {
      if (!isLoggedIn()) {
        return
      }
      this.stompClient.connect(
        {},
        (frame) => {
          Bus.$emit('socket_connected')
          this.connected = true
          this.stompClient.subscribe(CHANNEL, (payload) => {
            if (this.immediateSync) {
              // Trigger refetch of action
              this.triggerAction(payload.body)
            } else {
              const eventType = payload.body.split('|')[0]
              const executionOn = payload.body.split('|')[1]
              const eventObj = {
                eventType,
                executionOn,
              }
              // check action already in queue or not
              if (!this.executableActionMap.has(eventObj.eventType)) {
                this.executableActionMap.set(eventObj.eventType, eventObj)
              }
            }
          })
          this.stompClient.subscribe(CHAT_CHANNEL, (payload) => {
            let body
            if (typeof payload.body === 'string') {
              body = JSON.parse(payload.body)
            } else {
              body = payload.body
            }
            Bus.$emit('chat_channel_updated', body)
          })
          this.stompClient.subscribe(CHAT_COUNT_CHANNEL, (payload) => {
            Bus.$emit('pending_chat_count_updated', parseInt(payload.body))
          })
          this.stompClient.subscribe(UINOTIFICATION_CHANNEL, (payload) => {
            let body
            if (typeof payload.body === 'string') {
              body = JSON.parse(payload.body)
            } else {
              body = payload.body
            }
            getNotificationPermission()
              .then(() => {
                if (
                  ((this.$store.state || {}).auth || {}).user.id === body.userId
                ) {
                  showDesktopNotification('', body.title, () => {
                    const notification = transformNotification(body)
                    const link = getDetailLinkForModule(
                      notification.refModel,
                      notification.linkId,
                      this.$modules,
                      notification.type
                    )
                    if (link.link) {
                      this.$router.push(link.link)
                    }
                  })
                }
              })
              .catch(() => {
                Bus.$emit('ui_notification_updated', body)
              })
          })
          this.stompClient.subscribe(UINOTIFICATIONCOUNT_CHANNEL, (payload) => {
            let body
            if (typeof payload.body === 'string') {
              body = JSON.parse(payload.body)
            } else {
              body = payload.body
            }
            Bus.$emit('ui_notification_count_updated', body)
          })
          this.stompClient.subscribe(CONTACTOPS_CHANNEL, (payload) => {
            let body
            if (typeof payload.body === 'string') {
              body = JSON.parse(payload.body)
            } else {
              body = payload.body
            }
            const currentUser = getCurrentUser()
            if (
              ('userId' in body && currentUser.id === body.userId) ||
              'userId' in body === false
            ) {
              Bus.$emit('contactops_channel_updated', body)
            }
          })
        },
        () => {
          this.connected = false
          this.reconnect()
        }
      )
    },
    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect()
      }
      this.connected = false
    },
    triggerAction(key) {
      const action = ActionsMap.filter(
        (x) => x.serverKey.toLowerCase() === key.toLowerCase()
      )
      // if this action is not supported then skip it.
      if (action.length) {
        action.forEach((a) => {
          if (
            this.supportedVuexActions &&
            this.supportedVuexActions.indexOf(a.vuexKey) === -1
          ) {
            return
          }
          this.$store.dispatch(`${a.vuexKey}/${a.action}`, {
            skipidlecheck: true,
          })
          if (a.serverKey === 'logout') {
            const currentUser = getCurrentUser()
            Bus.$emit('auth:logout', currentUser)
          }
        })
      }
    },
    executeVuexCallActions() {
      if (this.executableActionMap.size > 0) {
        this.executableActionMap.forEach((eventObj, key) => {
          if (eventObj.executionOn) {
            setTimeout(() => {
              this.executableActionMap.delete(key)
              this.triggerAction(key)
            }, eventObj.executionOn * 1000)
          } else {
            this.executableActionMap.delete(key)
            this.triggerAction(key)
          }
        })
      }
    },
  },
  render() {
    return this.$scopedSlots.default({})
  },
}
</script>
