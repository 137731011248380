<template>
  <SearchBarProvider :module-name="moduleName" :prevent-api="onlyKeywords">
    <Search
      ref="searchRef"
      v-bind="$attrs"
      :module-name="moduleName"
      :only-keywords="onlyKeywords"
      v-on="listeners"
      @change="$emit('change', $event)"
    />
  </SearchBarProvider>
</template>

<script>
import SearchBarProvider from './search-bar-provider'
import Search from './search'
export default {
  name: 'FlotoSearchBar',
  components: { SearchBarProvider, Search },
  inheritAttrs: false,
  model: {
    event: 'change',
  },
  props: {
    moduleName: { type: String, required: true },
    onlyKeywords: { type: Boolean, default: false },
  },
  computed: {
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    addKeyword(k) {
      this.$refs.searchRef.addKeyword(k)
    },
  },
}
</script>
