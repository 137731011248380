<template>
  <span v-if="ssoConfig.ssoEnabled" class="mr-2">
    <!-- <MPopover
      v-if="ssoConfig.userInputRequired"
      ref="popover"
      :key="rendorCount"
      placement="bottomRight"
      :overlay-style="{ width: '300px' }"
      @show="handlePopoverShow"
    >
      <template v-slot:trigger>
        <MButton id="sso-login" variant="primary" class="mr-2">
          {{ $t('sso_login') }}
        </MButton>
      </template>
      <FlotoForm :show-notification="false" @submit="handleFormSubmit">
        <FlotoFormItem
          id="email-input"
          ref="input"
          v-model="email"
          rules="required|email"
          :label="$tc('email')"
          :placeholder="$tc('email')"
        />
        <template v-slot:submit>
          <div class="flex justify-end">
            <MButton size="small" type="submit" class="mx-1">
              {{ $t('next') }}
            </MButton>
            <MButton
              size="small"
              variant="default"
              class="mx-1"
              @click="hideMenu"
            >
              {{ $t('cancel') }}
            </MButton>
          </div>
        </template>
      </FlotoForm>
    </MPopover> -->
    <FlotoLink
      v-if="
        !ssoConfig.isMspPortal &&
        (ssoConfig.userInputRequired || ssoConfig.enforceSsoLoginOnly)
      "
      :to="$modules.getModuleRoute('auth', 'sso-login')"
      block
      as-button
      variant="primary"
    >
      {{ $t('sso_login') }}
    </FlotoLink>
    <a v-else href="/api/public/sso/authenticate">
      <MButton id="sso-login" variant="primary">
        {{ $t('sso_login') }}
      </MButton>
    </a>
  </span>
</template>
<script>
import { SsoConfigComputed } from '@state/modules/sso-config'
export default {
  name: 'SsoLoginBtn',
  data() {
    return {
      rendorCount: 1,
      email: '',
    }
  },
  computed: {
    ...SsoConfigComputed,
  },
  methods: {
    handlePopoverShow() {
      setTimeout(() => {
        this.$refs.input.focus()
      }, 250)
    },
    handleFormSubmit() {
      if (this.email) {
        window.open(
          `/api/public/sso/authenticate?useridentity=${encodeURIComponent(
            this.email
          )}`,
          '_self'
        )
        this.hideMenu()
      }
    },
    hideMenu() {
      this.$emit('hide')
      this.$refs.popover.hide()
    },
  },
}
</script>
