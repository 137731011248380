<template>
  <div class="inline-block search-selector">
    <FlotoScrollDropdown
      ref="dropdown"
      :disabled="showDeleteConfirmModalFor > 0"
      :options="options"
      :value="selectedSearch ? selectedSearch.id : undefined"
      @change="handleChange"
      @show="handleDropdownShow"
    >
      <template v-slot:trigger="{ toggle }">
        <div
          class="inline-block ml-4 mx-3 text-neutral cursor-pointer md-text-base"
          @click="toggle"
        >
          {{ (selectedSearch || {}).name }}
          <MIcon name="chevron-down" class="ml-1" />
        </div>
      </template>
      <template v-slot:before-menu>
        <div class="my-2 px-2">
          <MInput
            ref="searchBox"
            v-model="searchQuery"
            :placeholder="$t('search')"
          />
        </div>
      </template>
      <template v-slot:menu-item="{ item, selectItem }">
        <div class="flex justify-between" style="min-width: 18rem">
          <a
            href="javascript:;"
            class="item-link flex-1"
            @click.stop="selectItem(item)"
          >
            {{ item.name }}
          </a>
          <div class="action-links flex-none ml-4 flex items-center">
            <span
              v-if="item.id !== pinnedSearch && allowPin"
              href="javascript:;"
              class="text-neutral mr-2 hover:text-neutral cursor-pointer"
              @click="handlePinSearch(item)"
            >
              <MIcon name="thumbtack" />
            </span>
            <span
              v-if="item.canDelete && item.id !== pinnedSearch && allowPin"
              class="text-secondary-red hover:text-secondary-red cursor-pointer"
              @click.stop="showDeleteConfirmModalFor = item.id"
            >
              <MIcon name="trash-alt" />
            </span>
          </div>
        </div>
      </template>
    </FlotoScrollDropdown>
    <FlotoConfirmModal
      :open="showDeleteConfirmModalFor > 0"
      @hide="showDeleteConfirmModalFor = 0"
      @confirm="handleRemoveSearch(showDeleteConfirmModalFor)"
    >
      <template v-slot:icon>
        <MIcon name="search" size="2x" class="text-secondary-red" />
      </template>
      <template v-slot:message>
        {{ $t('confirm_remove_qualification') }}
      </template>
    </FlotoConfirmModal>
  </div>
</template>

<script>
import { searchList } from '@utils/arr'
import { PageComputed, PageMethods } from '@state/modules/page'
import {
  PreferenceComputed,
  PreferenceMethods,
} from '@state/modules/preference'

export default {
  name: 'FlotoSearchSelector',
  model: {
    event: 'change',
  },
  props: {
    moduleName: { type: String, required: true },
    excludeArchivedSpamQual: { type: Boolean, default: false },
    value: { type: Object, default: undefined },
    allowPin: {
      type: Boolean,
      defualt() {
        return true
      },
    },
    // eslint-disable-next-line
    persistSearch: { type: Boolean, defualt: true },
    availableSearches: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      showDeleteConfirmModalFor: false,
      searchQuery: '',
    }
  },
  computed: {
    ...PageComputed,
    ...PreferenceComputed,
    pinnedSearch() {
      return this.defaultSearchId(this.moduleName)
    },
    options() {
      let list = this[`${this.moduleName}Searches`] || this.availableSearches
      if (this.excludeArchivedSpamQual) {
        list =
          this[`${this.moduleName}Searches`].filter(
            (qual) =>
              ((qual || {}).systemName || '').indexOf('archive') === -1 &&
              ((qual || {}).systemName || '').indexOf('spam') === -1 &&
              ((qual || {}).systemName || '') !== 'requests watched by me'
          ) || this.availableSearches
      }
      if (!this.searchQuery) {
        return list
      }
      return searchList(list, this.searchQuery)
    },
    selectedSearch() {
      const currentSearch =
        this.value || this[`${this.moduleName}SelectedSearch`]
      return currentSearch
    },
  },
  watch: {
    availableSearches(newValue) {
      this.$emit('change', newValue[0])
    },
  },
  created() {
    if (this[`${this.moduleName}Loading`]) {
      this.$watch(`${this.moduleName}Loading`, function (newValue) {
        if (newValue === false) {
          this.$nextTick(() => {
            this.$emit('change', this.selectedSearch)
          })
        }
      })
    } else {
      this.$emit('change', this.selectedSearch)
    }
  },
  methods: {
    ...PageMethods,
    ...PreferenceMethods,
    handleDropdownShow() {
      this.searchQuery = ''
      setTimeout(() => {
        this.$refs.searchBox && this.$refs.searchBox.focus()
      }, 100)
    },
    handleRemoveSearch(itemId) {
      if (this[`${this.moduleName}RemoveSearch`]) {
        const shouldHide = this.selectedSearch.id === itemId
        this[`${this.moduleName}RemoveSearch`](itemId).then(() => {
          if (shouldHide) {
            this.$refs.dropdown.hide()
          }
          this.$nextTick(() => {
            this.$emit('change', this.selectedSearch)
          })
        })
      }
    },
    handleChange(itemKey) {
      const item = this.options.find((o) => o.key === itemKey)
      if (this[`${this.moduleName}SelectSearch`] && this.persistSearch) {
        this[`${this.moduleName}SelectSearch`](item)
        this.$nextTick(() => {
          this.$emit('change', this.selectedSearch)
        })
      } else {
        this.$nextTick(() => {
          this.$emit('change', item)
        })
      }
    },
    handlePinSearch(item) {
      if (this.moduleName && this.pinnedSearch) {
        this.updateModulePreference({
          moduleName: this.moduleName,
          defaultSearchId: item.id,
        })
        this.$refs.dropdown.hide()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.action-links {
  width: 2rem;
}

.search-selector {
  font-weight: normal;
}
</style>
