<template>
  <div class="flex flex-1 flex-col">
    <MCol :size="6">
      <FlotoFormItemReadOnly :label="$tc('asset_type')">
        <FlotoDropdownPicker
          v-model="assetModuleName"
          as-input
          :options="assetModuleOptions"
        />
      </FlotoFormItemReadOnly>
    </MCol>
    <MDivider />
    <AssetSelectionList
      v-if="assetModuleName"
      :key="assetModuleName"
      :selectable="selectable"
      :searchable="searchable"
      :module-name="assetModuleName"
      :fetch-fn="fetchAssetFn"
      :exclude-items-by-id="excludeItemsById"
      @selection-change="handleSelectionChange"
    />
  </div>
</template>

<script>
import AssetSelectionList from '@components/item-selection-list/asset-list'
import { searchAssetsApi } from '@modules/asset/asset-api'
import { PreferenceComputed } from '@state/modules/preference'

export default {
  name: 'AssetSelectionContainer',
  components: { AssetSelectionList },
  props: {
    sourceModuleName: { type: String, required: true },
    fetchFn: { type: Function, default: undefined },
    selectable: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    hiddenColumns: {
      type: Array,
      default() {
        return []
      },
    },
    excludeItemsById: {
      type: Array,
      default() {
        return []
      },
    },
    defaultSelectedItemIds: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      assetModuleName: this.$constants.ASSET_HARDWARE,
    }
  },
  computed: {
    ...PreferenceComputed,
    assetModuleOptions() {
      return [
        ...((this.myAllowedModules || []).indexOf(
          'asset.hardware_software_asset'
        ) >= 0
          ? [
              {
                text: this.$tc(this.$constants.ASSET_HARDWARE),
                key: this.$constants.ASSET_HARDWARE,
              },
              {
                text: this.$tc(this.$constants.ASSET_SOFTWARE),
                key: this.$constants.ASSET_SOFTWARE,
              },
            ]
          : []),
        // ...(this.sourceModuleName !== this.$constants.PURCHASE
        //   ? [
        //       {
        //         text: this.$tc(this.$constants.ASSET_SOFTWARE),
        //         key: this.$constants.ASSET_SOFTWARE,
        //       },
        //     ]
        //   : []),
        ...((this.myAllowedModules || []).indexOf('asset.non_it_asset') >= 0
          ? [
              {
                text: this.$tc(this.$constants.ASSET_NON_IT),
                key: this.$constants.ASSET_NON_IT,
              },
            ]
          : []),
        // ...((this.myAllowedModules || []).indexOf('asset.consumable_asset') >= 0
        //   ? [
        //       {
        //         text: this.$tc(this.$constants.ASSET_CONSUMABLE),
        //         key: this.$constants.ASSET_CONSUMABLE,
        //       },
        //     ]
        //   : []),
      ]
    },
  },
  watch: {
    assetModuleName(newValue, preValue) {
      if (newValue !== preValue) {
        this.$emit('selection-change', [])
        this.$emit('asset-sub-module-change', newValue)
      }
    },
  },
  created() {
    this.$emit('asset-sub-module-change', this.assetModuleName)
  },
  methods: {
    fetchAssetFn(module, criterias, limit, offset) {
      if (this.fetchFn) {
        return this.fetchFn(module, criterias, limit, offset)
      } else {
        return searchAssetsApi(this.assetModuleName, criterias, limit, offset)
      }
    },
    handleChangeModule(value) {
      this.assetModuleName = value.key
    },
    handleSelectionChange(items) {
      this.$emit('selection-change', items)
      this.$emit('asset-sub-module-change', this.assetModuleName)
    },
  },
}
</script>
