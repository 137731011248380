var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MRow',{attrs:{"gutter":0}},[_c('MCol',{staticClass:"search-container"},[_c('div',{staticClass:"flex items-center h-100"},[(
          _vm.value.length &&
          _vm.allowSaveSearch &&
          (_vm.selectedSearch.systemName || '').indexOf('archive') === -1
        )?_c('div',{staticClass:"save-search"},[_c('SaveSearch',{attrs:{"search":_vm.selectedSearch,"is-saved":!_vm.isDirty},on:{"save-as":function($event){return _vm.handleSaveSearch($event, true)},"save":_vm.handleSaveSearch}})],1):_vm._e(),_c('div',{staticClass:"internal-search-box flex flex-wrap items-center justify-start px-1"},[_vm._l((_vm.value),function(qualification,index){return _c('ValueSelector',{key:((qualification.paramName) + "-" + index),staticClass:"mx-1",attrs:{"value":qualification,"module-name":_vm.moduleName,"available-asset-type":_vm.availableAssetType,"source":_vm.getSourceFromParam(qualification.paramName)},on:{"remove":function($event){return _vm.removeQualification(index)},"change":function($event){return _vm.updateQualification($event, index)}}})}),_c('div',{class:{
            'flex-grow':
              !_vm.currentSelectedSource && !_vm.currentSelectedAdvancedSource,
          },style:({
            minWidth:
              !_vm.currentSelectedSource && !_vm.currentSelectedAdvancedSource
                ? '200px'
                : 'unset',
          })},[(!_vm.currentSelectedSource && !_vm.currentSelectedAdvancedSource)?_c('ParamSelector',{key:_vm.paramSelectorKey,ref:"paramSelector",attrs:{"sources":_vm.availableSources,"search-bar-placeholder":_vm.searchBarPlaceholder,"allow-keywords":_vm.allowKeywords},on:{"selected":_vm.sourceSelected,"advanced-search-param-selected":_vm.advancedSourceSelected}}):(_vm.currentSelectedSource)?_c('ValueSelector',{attrs:{"module-name":_vm.moduleName,"source":_vm.currentSelectedSource,"available-asset-type":_vm.availableAssetType},on:{"cancel":_vm.sourceCancelled,"change":_vm.handleNewQualificationSelected}}):(_vm.currentSelectedAdvancedSource)?_c('AdvancedValueSelector',{attrs:{"module-name":_vm.moduleName,"source":_vm.currentSelectedAdvancedSource,"available-asset-type":_vm.availableAssetType,"transform-sources-fn":_vm.transformSources},on:{"cancel":_vm.advancedSourceCancelled,"change":_vm.handleNewAdvancedQualificationSelected}}):_vm._e()],1)],2),(_vm.isDirty)?_c('FlotoDeleteBtn',{attrs:{"message":_vm.$t('confirm_reset_item', { item: _vm.$t('search') })},on:{"confirm":_vm.resetSearch}},[_c('div',{staticClass:"clear-search"},[_c('MIcon',{attrs:{"name":"times","size":"lg"}})],1)]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }